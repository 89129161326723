import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default class PoliticaDePrivacidade extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <html lang="pt-BR" amp />
                    <meta charSet="UTF-8" />
                    <title>Cia. Ibérica - Política de Privacidade</title>
                    <link rel="canonical" href="https://www.ciaiberica.com.br/politica-de-privacidade/" />
                    <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large" />
                    <meta property="og:locale" content="pt-BR" />
                    <meta property="og:title" content="Cia. Ibérica - Política de Privacidade" />
                    <meta property="og:description" content="Política de Privacidade Cia. Ibérica" />
                    <meta property="og:url" content="https://www.ciaiberica.com.br/politica-de-privacidade/" />
                    <meta property="og:sitename" content="Cia. Ibérica" />
                    <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                </Helmet>
                <Header/>
                <div className="container">
                    <div className="row mt-3 mb-3 text-center">
                        <h2>Política de Privacidade</h2>
                    </div>
                    <div className="row mt-3 mb-3">
                        <p>Esta Política de Privacidade têm como objetivo estabelecer as diretrizes do uso de dados
                            pessoais pela Cia. Ibérica, bem como os critérios para utilização dos ambientes públicos da
                            empresa.</p>
                        <p><b>1. DEFINIÇÕES BÁSICAS</b></p>
                        <p>Consideram-se, no âmbito desta Política de Privacidade, as seguintes definições:</p>
                        <ol className="list-group list-group-numbered">
                            <li className="list-group-item"><i>Website:</i> sítio virtual hospedado de forma
                                "estática" que apresenta as informações principais da Cia. Ibérica.
                            </li>
                            <li className="list-group-item"><i>Universidade Ibérica:</i> ambiente virtual de aprendizado
                                para a realização de cursos e instruções.
                            </li>
                        </ol>
                        <p><b>2. DA COLETA E DO USO DE DADOS PESSOAIS</b></p>
                        <p>A Cia. Ibérica coleta, no âmbito de suas páginas eletrônicas, os seguintes dados pessoais,
                            fornecidos de forma voluntária e consciente pelo(a) usuário(a):</p>
                        <p><b>2.1. FORMULÁRIO DE CONTATO:</b></p>
                        <ul className="list-group">
                            <li className="list-group-item">Nome</li>
                            <li className="list-group-item">E-mail</li>
                            <li className="list-group-item">Telefone</li>
                            <li className="list-group-item">Cidade e Estado</li>
                            <li className="list-group-item">CEP</li>
                        </ul>
                        <p><b>2.2. UNIVERSIDADE IBÉRICA</b></p>
                        <ul className="list-group">
                            <li className="list-group-item">Nome</li>
                            <li className="list-group-item">E-mail</li>
                            <li className="list-group-item">Telefone</li>
                            <li className="list-group-item">Cidade e Estado</li>
                        </ul>
                        <p>Os dados previstos no item 2.1. <b>não são utilizados para individualizar visitas ao site da
                            Cia. Ibérica.</b></p>
                        <p>Já os dados previstos no item 2.2. são usados para a realização dos cursos no ambiente
                            denominado "Universidade Ibérica", sendo requisitos mínimos para a utilização do
                            ambiente virtual de aprendizado. Poderão ser utilizados também para a criação de métricas,
                            envio de e-mail marketing e outros usos legais da Cia. Ibérica.</p>
                        <p>Os dados previstos em ambos os itens <b>não serão vendidos, transferidos e/ou cedidos à
                            terceiros, sendo usados única e exclusivamente pela Cia. Ibérica em seus usos legais
                            previstos neste item.</b></p>
                        <p><b>3. DA COLETA DE DADOS ANÔNIMOS DURANTE A NAVEGAÇÃO</b></p>
                        <p>A critério da Cia. Ibérica, poderão ser coletados dados estatísticos de navegação no website
                            Cia. Ibérica, como também no ambiente "Universidade Ibérica". Os dados estatísticos
                            coletados utilizam as plataformas Google Analytics e Google Tag Manager e seus dados, quando processados, são
                            anônimos. Em outras palavras, a Cia. Ibérica <b>não trabalhará para retirar a anonimização
                                dos dados descritos, tampouco os usará e/ou procurará usá-los em sua forma pura e
                                identificada</b>.</p>
                        <a href="https://policies.google.com/privacy?hl=pt-BR" rel="noopener,noreferrer" target="_blank">Aplicam-se as políticas previstas na Política de Privacidade da empresa Google.</a>
                        <p><b><i>Última atualização: 10/05/2022</i></b></p>
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}